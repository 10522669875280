import React, { useEffect, useState, useRef, useContext } from 'react'
import { ValidatedInput, Button } from '../../baseComponents'
import { LegalSection } from '../../LegalSection/LegalSection'
import { useApiClient } from '../../../hooks/useApiClient'
import {
  configurationContext,
  myAccountContext,
  pathContext,
} from '../../../contexts/contexts'
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook'

const CreateAccount = () => {
  PageTitleHook(
    'USCIS Online Account | Sign up for a USCIS account'
  )
  const { setAlert } = useContext(myAccountContext)
  const { setUrl } = useContext(pathContext)
  const { configuration } = useContext(configurationContext)

  const { mobileView } = configuration

  const [submitCreateAccount, setSubmitCreateAccount] = useState(false)
  const [emailInputVal, setEmailInputVal] = useState('')
  const [emailValidationMsg, setEmailValidationMsg] = useState('')
  const [disabledSubmit, setDisabledSubmit] = useState(false)

  const emailRef = useRef<HTMLInputElement>(null)

  const apiClient = useApiClient()

  const handleEmailInputChange = (e: any) => {
    setEmailInputVal(e.target.value)
    if (emailValidationMsg !== '') setEmailValidationMsg('')
    if (disabledSubmit) setDisabledSubmit(false)
  }

  const handleEnterDown = (e: any) => {
    if (e.key === 'Enter' && !disabledSubmit) {
      handleSubmit()
    }
  }

  const handleSignIn = () => {
    setUrl('sign-in')
  }

  const handleSubmit = () => {
    if (emailValidationMsg !== '') return
    setDisabledSubmit(true)
    setSubmitCreateAccount(true)
  }

  useEffect(() => {
    if (submitCreateAccount) {
      if (emailValidationMsg !== '') {
        emailRef.current!.focus()
      } 
      else if (!(/^[a-zA-Z0-9._'%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(emailInputVal.trim())) {
        setEmailValidationMsg('Please enter a valid email address. Please do not include accented letters or letters outside of the Latin alphabet.')
      } else {
        const url = window.location.origin + '/v1/users'
        apiClient
          .post(url, { email: emailInputVal.trim() })
          .then((res) => {
            setAlert({
              message: `A USCIS Account confirmation email has been sent to ${emailInputVal
                .replace(/\u2019/g, "'")
                .trim()}. Please follow the instructions in the email to confirm your USCIS Account request. If you do not receive the confirmation email within the next 10 minutes, please return to this page and request your confirmation instructions again.`,
              type: 'success',
              shouldPersist: true
            })
            setUrl('sign-in')
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                setEmailValidationMsg(
                  'Please enter a valid email address. Please do not include accented letters or letters outside of the Latin alphabet.'
                )
                emailRef.current!.focus()
              } else if (err.response.status === 400) {
                setUrl('/sign-in')
              }
            }
          })
      }
      setSubmitCreateAccount(false)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailValidationMsg, submitCreateAccount])

  return (
    <div className="card mx-auto" data-testid="create-account-container">
      <div className="mb-6">
        <h1 className="text-2xl my-3 font-normal text-dhs_font_gray">
          Create Your Online Account
        </h1>
        <p className="font-normal">
          Your USCIS account is only for you.  Do not create an account to share with family or friends.  Each person should have their own online account even if they are minors.  Individual accounts allow us to best serve you and protect your personal information.
        </p>
        <p className="pt-4">
          You must provide your own email address below if you are the one who is filing a form online, submitting an online request, or tracking a case. Your email address is used to log in to your USCIS online account. All USCIS email communications will be sent to this address.
        </p>
        <div data-testid="email-input" className="my-3 pt-3">
          <ValidatedInput
            label="Email Address"
            required
            msg={emailValidationMsg}
            onChange={handleEmailInputChange}
            value={emailInputVal}
            type="text"
            inputMode="email"
            ref={emailRef}
            onKeyDown={handleEnterDown}
          />
        </div>
        {/* Must use onMouseDown on the submit button because of the onBlur field validations. onClick causes user to have to click button twice */}
        <div className="button-container mt-4 mb-6">
          <Button
            id="submit-btn"
            text="Submit"
            disabled={disabledSubmit}
            onMouseDown={handleSubmit}
            onKeyDown={handleEnterDown}
          />
        </div>
        <div className="flex flex-col">
          <h2 className="text-xl">Already have an account?</h2>
          <button
            data-testid="sign-in-link"
            onClick={handleSignIn}
            className="text-dhs_light_blue hover:text-dhs_focus_blue focus:ring-1 focus:ring-dhs_focus_blue no-underline font-bold mt-1 mr-auto"
          >
            Sign In
          </button>
        </div>
      </div>
      {mobileView ? null : (
        <>
          <div className="bg-gray-200 h-px max-w-full"></div>
          <LegalSection />
        </>
      )}
    </div>
  )
}
export default CreateAccount
