import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  configurationContext,
  myAccountContext,
  pathContext,
} from '../../../contexts/contexts'
import dhs_white from '../../../assets/images/header/dhs_seal_1R.svg'
import dhs_white_anniversary from '../../../assets/images/header/dhs_seal_1R_anniversary.svg'
import { SignOut } from '../SignOut'
import { useLocation } from 'react-router-dom'

export const HeaderBannerBar: FunctionComponent = () => {
  const { showAnniversaryLogo, user } = useContext(myAccountContext)
  const { userState } = useContext(pathContext)
  const { configuration } = useContext(configurationContext)
  const { mobileView } = configuration

  const [showSignout, setShowSignout] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const excludeSignOutForPaths = [
      'sign-in',
      'create-account',
      'terms',
      'forgot-password',
      'resend-confirmation',
      'login-gov-faq',
      '/delete-account/response',
      'invitation-expired'
    ]
    if (
      userState === 'forgot-password' ||
      excludeSignOutForPaths.some((value) =>
        location.pathname.includes(value)
      ) ||
      mobileView
    ) {
      setShowSignout(false)
    } else {
      setShowSignout(true)
    }
  }, [location, mobileView, userState])

  useEffect(() => {
    if (user.account_deletion_state === 'deletion_failed') {
      setShowSignout(true)
    }
  }, [user.account_deletion_state])

  const imgSrc = showAnniversaryLogo ? dhs_white_anniversary : dhs_white
  const imgAlt = `U.S. Department of Homeland Security seal U.S. citizenship and immigration services${
    showAnniversaryLogo ? ' DHS at 20' : ''
  }`

  return (
    <div
      data-testid="headerbannerbar-div"
      className="bg-dhs-blue py-2 pl-4 sm:pb-3 lg:py-4"
    >
      <div className="flex flex-row justify-between max-w-screen-lg lg:mx-auto">
        <a
          aria-label="U.S. Department of Homeland Security seal U.S. citizenship and immigration services"
          href="/"
          className={showAnniversaryLogo ? 'h-20' : ''}
        >
          <img
            className={
              showAnniversaryLogo
                ? 'mt-2 sm:mt-0 h-16 sm:h-full'
                : 'pt-2 sm:pt-0 h-4/5 sm:h-full'
            }
            src={imgSrc}
            alt={imgAlt}
          />
        </a>
        {showSignout && <SignOut />}
      </div>
    </div>
  )
}
