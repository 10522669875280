import React, { FunctionComponent, useContext } from 'react'
import { myAccountContext } from '../../../contexts/contexts'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dhsfooterseal from '../../../assets/images/footer/dhs_logo.svg';
import dhsfooterseal_anniversary from '../../../assets/images/footer/dhs_logo_anniversary.svg';

export const FooterMidLogo: FunctionComponent = () => {
  const { showAnniversaryLogo } = useContext(myAccountContext)

  const imgSrc = showAnniversaryLogo ? dhsfooterseal_anniversary : dhsfooterseal
  const imgAlt = `U.S. Department of Homeland Security Seal, U.S. Citizenship and Immigration Services${showAnniversaryLogo ? ' DHS at 20' : ''}`

  return (
      <img
        className='w-64 sm:w-72 mt-7 sm:mt-0 sm:mb-1 sm:ml-16 sm:ml-8'
        src={imgSrc}
        alt={imgAlt}
      />
  );
};

export default FooterMidLogo;